/**
 * !!UPDATE: Changes to layup ff naming requirements
 * Prepend layup- to new feature flags added in layup environments
 *
 * Example feature flag entry
 *
 * 'layup-example-feature-flag': {
 *    squad: 'winners', // squad name
 *    ticketNo: 'GO-28991' // Link to Jira ticket of the feature that uses this flag or a description, e.g. https://gameonsport.atlassian.net/browse/GO-25136
 *    lastUpdatedDate: '1 Jan 2024' // Date the feature flag is created or updated, aim to clean up after a month in prod. Valid date string when put into new Date() function
 *    longLived: true, // Set to true if this feature flag cannot be removed safely and does not have a planned removal task (not recommended)
 *  },
 */

export type FeatureFlag =
  | 'layup-afl-account-linking'
  | 'ca-account-linking'
  | 'layup-afl-id-login'
  | 'layup-ball-by-ball'
  | 'layup-block-pending-request-to-play'
  | 'layup-mastercard-click-to-pay'
  | 'layup-concussion'
  | 'layup-concussion-profile'
  | 'layup-cricket-fielding-statistics'
  | 'layup-cup-competition'
  | 'layup-ecal-embed'
  | 'layup-environment-labels'
  | 'layup-experiment-auth-payment'
  | 'layup-football-au'
  | 'layup-game-payments-modal'
  | 'layup-individual-statistics-edit'
  | 'layup-kill-login'
  | 'layup-link-player-stats'
  | 'layup-live-scoring-period-scores'
  | 'layup-live-scoring-scorecard'
  | 'layup-myteams-escoring-cta'
  | 'layup-optional-account-linking'
  | 'layup-offence-list'
  | 'layup-over-summaries'
  | 'layup-profile-connected-accounts'
  | 'layup-profile-photo-verification'
  | 'layup-registration-kill-switch'
  | 'layup-request-to-play'
  | 'disable-nsw-vouchers'
  | 'layup-deferred-registration-fees'
  | 'layup-post-products'
  | 'layup-mastercard-click-to-pay-promotion'
  | 'layup-mastercard-click-to-pay-banner-v2'
  | 'layup-pending-registrations-via-cron'
  | 'layup-charity-on-checkout'
  | 'layup-tpf-easy-merch'

interface FeatureFlagDetails {
  squad: string
  ticketNo: string
  lastUpdatedDate: string
  longLived?: true
}

export const featureFlags: Record<FeatureFlag, FeatureFlagDetails> = {
  'layup-afl-account-linking': {
    squad: 'koalas',
    ticketNo: 'GO-25407',
    lastUpdatedDate: '14 Sep 2023',
  },
  // layup-ca-account-linking
  'ca-account-linking': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'layup-afl-id-login': {
    squad: 'baguettes',
    ticketNo: 'GO-28727',
    lastUpdatedDate: '16 May 2024',
    longLived: true,
  },
  'layup-ball-by-ball': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2024',
  },
  'layup-block-pending-request-to-play': {
    squad: 'anzacs',
    ticketNo: 'GO-23090',
    lastUpdatedDate: '1 Jan 2022',
  },
  'layup-mastercard-click-to-pay': {
    squad: 'baguettes',
    ticketNo: 'GO-27325',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'layup-concussion': {
    squad: 'winners',
    ticketNo: 'GO-30939',
    lastUpdatedDate: '29 Jan 2025',
  },
  'layup-concussion-profile': {
    squad: 'winners',
    ticketNo: 'GO-30939',
    lastUpdatedDate: '29 Jan 2025',
  },
  'layup-cricket-fielding-statistics': {
    squad: 'anzacs',
    ticketNo: 'GO-25993',
    lastUpdatedDate: '16 Aug 2023',
  },
  'layup-cup-competition': {
    squad: 'winners',
    ticketNo: 'GO-30575',
    lastUpdatedDate: '21 Jan 2025',
  },
  'layup-ecal-embed': {
    squad: 'nebula',
    ticketNo: 'GO-30198',
    lastUpdatedDate: '25 Sep 2024',
    longLived: true,
  },
  'layup-environment-labels': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'layup-experiment-auth-payment': {
    squad: 'payments',
    ticketNo: 'GO-28544',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'layup-football-au': {
    squad: 'winners',
    ticketNo: 'GO-28237',
    lastUpdatedDate: '10 Apr 2024',
  },
  'layup-game-payments-modal': {
    squad: 'payments',
    ticketNo: 'GO-29911',
    lastUpdatedDate: '14 Nov 2024',
  },
  'layup-individual-statistics-edit': {
    squad: 'winners',
    ticketNo: 'GO-28807',
    lastUpdatedDate: '11 Dec 2024',
  },
  'layup-kill-login': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'layup-link-player-stats': {
    squad: 'winners',
    ticketNo: 'GO-30507',
    lastUpdatedDate: '5 Dec 2024',
  },
  'layup-live-scoring-period-scores': {
    squad: 'nebula',
    ticketNo: 'GO-27032',
    lastUpdatedDate: '22 Nov 2023',
  },
  'layup-live-scoring-scorecard': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'layup-myteams-escoring-cta': {
    squad: 'nebula',
    ticketNo: 'GO-31119',
    lastUpdatedDate: '3 Feb 2025',
  },
  'layup-optional-account-linking': {
    squad: 'platform',
    ticketNo: 'GO-29655',
    lastUpdatedDate: '15 Oct 2024',
    longLived: true,
  },
  'layup-offence-list': {
    squad: 'winners',
    ticketNo: 'GO-28904',
    lastUpdatedDate: '2 Dec 2024',
  },
  'layup-over-summaries': {
    squad: 'koalas',
    ticketNo: 'GO-25670',
    lastUpdatedDate: '1 Jan 2024',
  },
  'layup-profile-connected-accounts': {
    squad: 'platform',
    ticketNo: 'GO-29609',
    lastUpdatedDate: '11 Dec 2024',
  },
  'layup-profile-photo-verification': {
    squad: 'payments',
    ticketNo: 'GO-31065',
    lastUpdatedDate: '13 Jan 2025',
  },
  'layup-registration-kill-switch': {
    squad: 'sports',
    ticketNo: 'GO-20708',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'layup-request-to-play': {
    squad: 'anzacs',
    ticketNo: 'GO-23090',
    lastUpdatedDate: '1 Jan 2022',
  },
  // layup-disable-nsw-vouchers
  'disable-nsw-vouchers': {
    squad: 'payments',
    ticketNo: 'GO-28104',
    lastUpdatedDate: '1 Mar 2024',
    longLived: true,
  },
  'layup-deferred-registration-fees': {
    squad: 'payments',
    ticketNo: 'GO-30075',
    lastUpdatedDate: '2 Sep 2024',
    longLived: true,
  },
  'layup-post-products': {
    squad: 'nebula',
    ticketNo: 'GO-30663',
    lastUpdatedDate: '26 Nov 2024',
  },
  'layup-mastercard-click-to-pay-promotion': {
    squad: 'payments',
    ticketNo: 'GO-30859',
    lastUpdatedDate: '01 Dec 2024',
  },
  'layup-mastercard-click-to-pay-banner-v2': {
    squad: 'payments',
    ticketNo: 'GO-30859',
    lastUpdatedDate: '06 Dec 2024',
  },
  'layup-pending-registrations-via-cron': {
    squad: 'payments',
    ticketNo: 'GO-30875',
    lastUpdatedDate: '16 Dec 2024',
  },
  'layup-charity-on-checkout': {
    squad: 'payments',
    ticketNo: 'GO-29966',
    lastUpdatedDate: '18 Dec 2024',
  },
  'layup-tpf-easy-merch': {
    squad: 'payments',
    ticketNo: 'GO-31053',
    lastUpdatedDate: '04 Feb 2025',
  },
}
